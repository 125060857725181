import { graphql } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"
import * as React from "react"
import SEO from "../components/seo"
import PostTemplate from "../templates/post"

const NotFoundPage = ({ data, location }) => {
  return (
    <>
      <SEO title="404: Not Found" />
      <div className="container">
        <div className="row">
          <div className="col">
            <h1>404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    post: PostTemplate,
  },
})

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
